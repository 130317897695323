<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('src\\views\\bookings\\detail.7182') }}</v-toolbar-title>
        </v-toolbar>
        
        <div
            class="pa-6 mx-0 my-2"
            style="border: 1px solid #00000061; border-radius: 4px;">
            <v-row class="mx-0">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.2722') }}</v-col>
                <v-col cols="12" sm="8">{{ booking.createdAt | moment('DD.MM.YYYY HH:mm') }}</v-col>
            </v-row>
            <v-row class="mx-0">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.2671') }}</v-col>
                <v-col cols="12" sm="8">
                    {{ $t(BOOKING_STATUS_TITLE[booking.status]) }}
                    <v-menu v-if="user.adminAccess && user.adminAccess.trips >= ACCESS.WRITE">
                        <template v-slot:activator="{ attrs, on }">
                            <v-icon
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                            >mdi-pencil</v-icon>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(status, index) in BOOKING_STATUS_TITLE"
                                :key="index"
                                @click="setStatus(index)"
                            >
                                <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="mx-0" v-if="booking.user">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.6146') }}</v-col>
                <v-col cols="12" sm="8">{{ booking.user.firstname }} {{ booking.user.lastname }}</v-col>
            </v-row>
            <v-row class="mx-0" v-if="booking.trip">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.8802') }}</v-col>
                <v-col cols="12" sm="8">{{ booking.trip.name }}</v-col>
            </v-row>
            <v-row class="mx-0">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.3681') }}</v-col>
                <v-col cols="12" sm="8" v-html="priceFormated(booking.price, booking.currency)"></v-col>
            </v-row>
            <v-row class="mx-0">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.6565') }}</v-col>
                <v-col cols="12" sm="8" v-html="priceFormated(booking.deposit, booking.currency)"></v-col>
            </v-row>
            <v-row class="mx-0">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.8783') }}</v-col>
                <v-col cols="12" sm="8">{{ booking.start | moment('DD.MM.YYYY') }} - {{ booking.finish | moment('DD.MM.YYYY') }}</v-col>
            </v-row>
            <v-row class="mx-0">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.722') }}</v-col>
                <v-col cols="12" sm="8">{{ booking.places }}</v-col>
            </v-row>
            <v-row class="mx-0" v-if="booking.mainTraveler">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.2652') }}</v-col>
                <v-col cols="12" sm="8">{{ $t('src\\views\\bookings\\detail.3456', { '1': booking.mainTraveler.firstname, '2': booking.mainTraveler.lastname, '3': booking.mainTraveler.birthday, '4': booking.mainTraveler.gender }) }}</v-col>
            </v-row>
            <template v-for="(traveler, index) in booking.additionalTravelers">
                <v-row :key="`traveler-${index}`" class="mx-0">
                    <v-col cols="12" sm="4"></v-col>
                    <v-col cols="12" sm="8">{{ $t('src\\views\\bookings\\detail.9567', { '1': traveler.firstname, '2': traveler.lastname, '3': traveler.birthday, '4': traveler.gender }) }}</v-col>
                </v-row>
            </template>
            <v-row class="mx-0" v-if="booking.mainTraveler">
                <v-col cols="12" sm="4">{{ $t('src\\views\\bookings\\detail.6567') }}</v-col>
                <v-col cols="12" sm="8" v-if="booking.mainTraveler.phone">{{ booking.mainTraveler.phone }}</v-col>
                <v-col cols="12" sm="4" v-if="booking.mainTraveler.phone && booking.mainTraveler.mail"></v-col>
                <v-col cols="12" sm="8" v-if="booking.mainTraveler.mail">{{ booking.mainTraveler.mail }}</v-col>
            </v-row>
        </div>

        <v-toolbar flat>
            <v-toolbar-title>{{ $t('src\\views\\bookings\\detail.2783') }}</v-toolbar-title>
        </v-toolbar>

        <v-data-table
            :headers="paymentHeaders"
            :items="payments"
            class="elevation-1"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template v-slot:[`item.trip`]="{ item }">
                {{ item.trip.name }}
            </template>
            <template v-slot:[`item.user`]="{ item }">
                {{ item.user.firstname }} {{ item.user.lastname }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <span v-html="priceFormated(item.amount, booking.currency)"></span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                {{ $t(PAYMENT_STATUS_TITLE[item.status]) }}
                <!-- <v-menu>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="primary"
                            small
                            class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                        >{{ $t('src\\views\\bookings\\detail.mdipencil') }}</v-icon>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(status, index) in PAYMENT_STATUS_TITLE"
                            :key="index"
                            @click="setPaymentStatus(item._id, index)"
                        >
                            <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
            </template>
            <template v-slot:[`item.remove`]="{ item }">
                <v-icon color="primary" @click="removePayment(item._id)">{{ $t('src\\views\\bookings\\detail.mdicloseoctagonoutline') }}</v-icon>
            </template>
        </v-data-table>

        <v-row
            align="center"
            justify="space-between"
            class="mx-0 mt-8">
            <v-btn
                @click="back">{{ $t('src\\views\\bookings\\detail.3880') }}</v-btn>
        </v-row>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { priceFormated } from '../../helpers';
    import { BOOKING_STATUS_TITLE, PAYMENT_STATUS_TITLE } from '../../vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'QuizDetail',
        components: {
        },
        data: () => ({
            priceFormated,
            ACCESS,
            BOOKING_STATUS_TITLE,
            PAYMENT_STATUS_TITLE,
            paymentHeaders: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                booking: state => state.entity
            }),
            ...mapState('payments', {
                payments: state => state.entities
            }),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('bookings/get', { id: this.$route.params.id });
                await this.getPayments();
            } else {
                await this.$router.push({ name: 'bookings-list' });
            }
            return this.paymentHeaders = [
                { text: this.$t('src\\views\\bookings\\detail.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\bookings\\detail.amount'), value: 'amount' },
                { text: this.$t('src\\views\\bookings\\detail.status'), value: 'status' },
                { text: this.$t('src\\views\\bookings\\detail.reason'), value: 'reason' },
            ]
        },
        methods: {
            async back() {
                await this.$router.push({ name: 'bookings-list' });
            },
            async getPayments() {
                await store.dispatch('payments/fetch', { filter: { booking: this.$route.params.id } });
            },
            async setStatus(status) {
                await store.dispatch('bookings/setStatus', { id: this.booking._id, status });
                await store.dispatch('bookings/get', { id: this.$route.params.id });
            },
            async remove() {    
                await store.dispatch('bookings/delete', { id: this.booking._id });
                await this.$router.push({ name: 'bookings-list' });
            },
            async setPaymentStatus(id, status) {
                await store.dispatch('payments/setStatus', { id, status });
                await this.getPayments();
            },
            async removePayment(id) {
                await store.dispatch('payments/delete', { id });
                await this.getPayments();
            },
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
